/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.segment textarea:first-line {
  font-weight: bold;
}

ion-alert.md.select-alert {
  --width: 290px;
  --max-width: 290px;
}

ion-list.segment {
  max-height: calc(100vh - 280px);
  overflow-x: auto;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-list.segment {
    max-height: 245px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-list.segment {
    max-height: 350px;
  }
}

ion-input:read-only {
  --padding-bottom: 0 !important;
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

ion-textarea:read-only {
  --padding-bottom: 0 !important;
  --padding-top: 0 !important;
  cursor: pointer;

  textarea {
    cursor: pointer;
  }
}

ion-list-header {
  min-height: 25px;
  background: var(--ion-color-step-50);

  ion-label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
    color: var(--ion-color-step-600);
  }
}

ion-avatar + ion-card-header ion-card-title {
  font-size: 20px;
}

ion-toast {
  --width: 230px;
  text-align: center;
}